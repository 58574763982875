// Міксин

@import './vendors/mixin';

// Скидання стилів
@import './utils/value';
@import './utils/reset';

// Матеріали проекту
@import './component/header';
@import './component/hero';
@import './component/iso';
@import './component/offer';
@import './component/benefist';
@import './component/footer';
